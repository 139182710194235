import type { CssVarsThemeOptions } from "@mui/joy/styles";
import { extendTheme } from "@mui/joy/styles";
import { deepmerge } from "@mui/utils";
import "@fontsource/inter/200.css";
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";

import { KeyboardArrowDown } from "@mui/icons-material";
import colors from "../helpers/colors";
import breakpoints from "../helpers/breakpoints";

declare module "@mui/joy/styles" {
  interface TypographySystemOverrides {
    "title-xs": true;
    kpi: true;
  }
}

const createTheme = (override?: CssVarsThemeOptions) => {
  const highDPIScreen = matchMedia(
    "(-webkit-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2), (min-resolution: 192dpi)"
  ).matches;
  const defaultThemeOptions: CssVarsThemeOptions = {
    shadow: {
      xs: "0px 1px 2px 0px rgba(24, 37, 51, 0.10)",
      sm: "0px 3px 4px 0px rgba(24, 37, 51, 0.04)",
      md: "0px 7px 15px 0px rgba(24, 37, 51, 0.05)",
      lg: "0px 12px 25px 0px rgba(24, 37, 51, 0.06)",
      xl: "0px 20px 40px 0px rgba(24, 37, 51, 0.07)",
    },
    colorSchemes: {
      light: {
        palette: {
          //taken from https://www.figma.com/file/HfT3a7QPgAqrOl3NjqJGTn/Prototype?node-id=1%3A515&mode=dev
          neutral: {
            ...colors.grey,
            outlinedDisabledColor: "var(--joy-palette-neutral-500)",
            plainColor: "var(--joy-palette-neutral-900)",
            plainDisabledColor: "var(--joy-palette-neutral-500)",
            plainHoverBg: "var(--joy-palette-neutral-200)",
            softBg: "var(--joy-palette-neutral-200)",
            softColor: "var(--joy-palette-neutral-800)",
            softDisabledBg: "var(--joy-palette-neutral-200)",
            softDisabledColor: "var(--joy-palette-neutral-500)",
            softHoverBg: "var(--joy-palette-neutral-300)",
            solidActiveBg: "var(--joy-palette-neutral-300)",
            solidBg: "var(--joy-palette-common-white)",
            solidColor: "var(--joy-palette-neutral-800)",
            solidDisabledColor: "var(--joy-palette-neutral-500)",
            solidDisabledBg: "var(--joy-palette-neutral-200)",
            solidHoverBg: "var(--joy-palette-neutral-100)",
          },
          primary: {
            ...colors.blue,
            outlinedDisabledColor: "var(--joy-palette-neutral-500)",
            plainDisabledColor: "var(--joy-palette-neutral-500)",
            softColor: "var(--joy-palette-primary-800)",
            softDisabledBg: "var(--joy-palette-primary-200)",
            softDisabledColor: "var(--joy-palette-neutral-500)",
            solidDisabledBg: "var(--joy-palette-neutral-200)",
            solidDisabledColor: "var(--joy-palette-neutral-500)",
          },
          background: {
            backdrop: "rgba(41, 55, 70, 0.25)",
            body: "var(--joy-palette-neutral-100)",
            surface: "var(--joy-palette-common-white)",
            level1: "var(--joy-palette-neutral-200)",
            level2: "var(--joy-palette-neutral-300)",
            level3: "var(--joy-palette-neutral-400)",
            tooltip: "var(--joy-palette-common-white)",
          },
          text: {
            icon: "var(--joy-palette-neutral-800)",
            secondary: "var(--joy-palette-neutral-600)",
            tertiary: "var(--joy-palette-neutral-500)",
          },
          warning: {
            ...colors.yellow,
            outlinedActiveBg: "var(--joy-palette-warning-200)",
            outlinedBorder: "var(--joy-palette-warning-200)",
            outlinedColor: "var(--joy-palette-warning-800)",
            outlinedDisabledBorder: "var(--joy-palette-neutral-200)",
            outlinedDisabledColor: "var(--joy-palette-neutral-500)",
            outlinedHoverBg: "var(--joy-palette-warning-50)",
            plainColor: "var(--joy-palette-warning-800)",
            plainActiveColor: "var(--joy-palette-warning-800)",
            plainDisabledColor: "var(--joy-palette-neutral-500)",
            plainActiveBg: "var(--joy-palette-warning-200)",
            plainHoverBg: "var(--joy-palette-warning-50)",
            softActiveBg: "var(--joy-palette-warning-200)",
            softActiveColor: "var(--joy-palette-neutral-800)",
            softBg: "var(--joy-palette-warning-50)",
            softColor: "var(--joy-palette-neutral-800)",
            softDisabledBg: "var(--joy-palette-neutral-200)",
            softDisabledColor: "var(--joy-palette-neutral-500)",
            softHoverBg: "var(--joy-palette-warning-100)",
            solidActiveBg: "var(--joy-palette-warning-600)",
            solidBg: "var(--joy-palette-warning-400)",
            solidColor: "var(--joy-palette-neutral-800)",
            solidDisabledBg: "var(--joy-palette-neutral-200)",
            solidDisabledColor: "var(--joy-palette-neutral-500)",
            solidHoverBg: "var(--joy-palette-warning-500)",
          },
          success: {
            ...colors.green,
            outlinedDisabledColor: "var(--joy-palette-neutral-500)",
            plainDisabledColor: "var(--joy-palette-neutral-500)",
            softDisabledBg: "var(--joy-palette-neutral-200)",
            softDisabledColor: "var(--joy-palette-neutral-500)",
            solidDisabledBg: "var(--joy-palette-neutral-200)",
            solidDisabledColor: "var(--joy-palette-neutral-500)",
          },
          danger: {
            ...colors.red,
            outlinedHoverBg: "var(--joy-palette-danger-100)",
            plainDisabledColor: "var(--joy-palette-neutral-500)",
            softDisabledBg: "var(--joy-palette-neutral-200)",
            softDisabledColor: "var(--joy-palette-neutral-500)",
            solidDisabledBg: "var(--joy-palette-neutral-200)",
            solidDisabledColor: "var(--joy-palette-neutral-500)",
          },
        },
      },
      dark: {
        palette: {
          background: {
            backdrop: "rgba(41, 55, 70, 0.25)",
            body: "#182533",
            surface: "var(--joy-palette-neutral-900)",
            level1: "var(--joy-palette-neutral-800)",
            level2: "var(--joy-palette-neutral-700)",
            level3: "var(--joy-palette-neutral-600)",
            popup: "var(--joy-palette-common-white)",
            tooltip: "var(--joy-palette-common-white)",
          },
          neutral: {
            ...colors.grey,
            outlinedDisabledBorder: "var(--joy-palette-neutral-700)",
            outlinedDisabledColor: "var(--joy-palette-neutral-600)",
            plainDisabledColor: "var(--joy-palette-neutral-600)",
            softDisabledColor: "var(--joy-palette-neutral-700)",
            softDisabledBg: "var(--joy-palette-neutral-900)",
            solidActiveBg: "var(--joy-palette-neutral-300)",
            solidBg: "var(--joy-palette-common-white)",
            solidColor: "var(--joy-palette-neutral-800)",
            solidDisabledColor: "var(--joy-palette-neutral-700)",
            solidDisabledBg: "var(--joy-palette-neutral-900)",
            solidHoverBg: "var(--joy-palette-neutral-100)",
          },
          text: {
            icon: "var(--joy-palette-neutral-100)",
          },
          danger: {
            ...colors.red,
            outlinedDisabledBorder: "var(--joy-palette-neutral-700)",
            outlinedHoverBg: "var(--joy-palette-danger-800)",
            plainDisabledColor: "var(--joy-palette-neutral-700)",
            softDisabledColor: "var(--joy-palette-neutral-700)",
            softDisabledBg: "var(--joy-palette-neutral-900)",
            solidDisabledBg: "var(--joy-palette-neutral-900)",
            solidDisabledColor: "var(--joy-palette-neutral-700)",
          },
          success: {
            ...colors.green,
            outlinedDisabledBorder: "var(--joy-palette-neutral-700)",
            outlinedDisabledColor: "var(--joy-palette-neutral-700)",
            plainDisabledColor: "var(--joy-palette-neutral-700)",
            softDisabledColor: "var(--joy-palette-neutral-700)",
            softDisabledBg: "var(--joy-palette-neutral-900)",
            solidDisabledColor: "var(--joy-palette-neutral-700)",
            solidDisabledBg: "var(--joy-palette-neutral-900)",
          },
          warning: {
            ...colors.yellow,
            outlinedActiveBg: "var(--joy-palette-warning-600)",
            outlinedBorder: "var(--joy-palette-warning-500)",
            outlinedColor: "var(--joy-palette-warning-50)",
            outlinedDisabledBorder: "var(--joy-palette-neutral-700)",
            outlinedDisabledColor: "var(--joy-palette-neutral-700)",
            outlinedHoverBg: "var(--joy-palette-warning-500)",
            plainColor: "var(--joy-palette-warning-50)",
            plainActiveColor: "var(--joy-palette-warning-50)",
            plainDisabledColor: "var(--joy-palette-neutral-700)",
            plainActiveBg: "var(--joy-palette-warning-600)",
            plainHoverBg: "var(--joy-palette-warning-500)",
            softActiveBg: "var(--joy-palette-warning-600)",
            softActiveColor: "var(--joy-palette-neutral-800)",
            softBg: "var(--joy-palette-warning-700)",
            softColor: "var(--joy-palette-neutral-800)",
            softDisabledBg: "var(--joy-palette-neutral-900)",
            softDisabledColor: "var(--joy-palette-neutral-700)",
            softHoverBg: "var(--joy-palette-warning-500)",
            solidActiveBg: "var(--joy-palette-warning-600)",
            solidBg: "var(--joy-palette-warning-400)",
            solidColor: "var(--joy-palette-neutral-800)",
            solidDisabledBg: "var(--joy-palette-neutral-900)",
            solidDisabledColor: "var(--joy-palette-neutral-700)",
            solidHoverBg: "var(--joy-palette-warning-500)",
          },
          primary: {
            ...colors.blue,
            outlinedDisabledBorder: "var(--joy-palette-neutral-700)",
            outlinedDisabledColor: "var(--joy-palette-neutral-600)",
            plainDisabledColor: "var(--joy-palette-neutral-600)",
            softDisabledColor: "var(--joy-palette-neutral-600)",
            solidDisabledColor: "var(--joy-palette-neutral-600)",
          },
        },
      },
    },
    radius: {
      xs: "3px",
      sm: "7px",
      md: "14px",
      lg: "21px",
      xl: "999px",
    },
    spacing: 5,
    fontWeight: { xs: 200 },
    fontSize: {
      xl4: "2.5rem",
      xl3: "2rem",
      lg: "1rem",
      md: "0.875rem",
      sm: "0.75rem",
      xs: "0.625rem",
    },
    lineHeight: {
      xs: 1.2,
      sm: 1.2,
      md: 1.5,
      lg: 1.5,
      xl: 1.5,
    },
    typography: {
      h1: {
        letterSpacing: 0,
      },
      h2: {
        letterSpacing: 0,
      },
      h3: {
        letterSpacing: 0,
      },
      h4: {
        letterSpacing: 0,
        lineHeight: 1.2,
      },
      "title-md": {
        lineHeight: 1.2,
      },
      "title-xs": {
        fontSize: "0.625rem",
        lineHeight: 1.2,
        fontWeight: "var(--joy-fontWeight-sm)",
      },
      kpi: {
        fontSize: "5.125rem",
        fontWeight: "var(--joy-fontWeight-xs)",
        lineHeight: 1,
        letterSpacing: -0.5,
      },
    },
    components: {
      JoyFormHelperText: {
        styleOverrides: {
          root: ({ theme }) => ({
            color: `var(--FormHelperText-color, ${theme.vars.palette.text.secondary})`,
          }),
        },
      },
      JoyDialogTitle: {
        defaultProps: {
          level: "h3",
          sx: { fontWeight: "sm" },
        },
      },
      JoySelect: {
        defaultProps: {
          indicator: <KeyboardArrowDown />,
        },
        styleOverrides: {
          root: ({ theme }) => ({
            borderRadius: theme.radius.lg,
          }),
          indicator: ({ theme }) => ({
            marginRight: theme.spacing(2),
            borderRadius: theme.radius.lg,
          }),
          button: ({ theme }) => ({
            marginLeft: theme.spacing(3),
          }),
        },
      },
      JoyCheckbox: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            gap: theme.spacing(1),
            ...(["lg", "md"].includes(ownerState.size || "") && {
              gap: theme.spacing(2),
            }),
          }),
        },
      },
      JoyAutocomplete: {
        defaultProps: {
          popupIcon: <KeyboardArrowDown />,
        },
        styleOverrides: {
          root: ({ theme }) => ({
            borderRadius: theme.radius.lg,
          }),
          popupIndicator: ({ theme }) => ({
            borderRadius: theme.radius.lg,
          }),
          startDecorator: ({ theme, ownerState }) => ({
            ...(ownerState.size === "sm" && {
              marginLeft: theme.spacing(0.5),
            }),
          }),
        },
      },
      JoyButton: {
        styleOverrides: {
          root: ({ theme, ownerState }) => [
            {
              "--Button-radius": theme.radius.xl,
              "--Button-gap": theme.spacing(ownerState.size === "sm" ? 1 : 2),
              "--Button-paddingBlock": theme.spacing(
                ownerState.size === "lg" ? 2 : 1
              ),
              "--Button-paddingInline": theme.spacing(
                ownerState.size === "lg" ? 4 : 3
              ),
              lineHeight: theme.vars.lineHeight.sm,
            },
            ownerState.size && {
              fontSize: theme.typography[`title-${ownerState.size}`].fontSize,
              ...(ownerState.size === "sm" && {
                "--Button-minHeight": "2rem",
              }),
              ...(ownerState.size === "md" && {
                "--Button-minHeight": "2.5rem",
              }),
              ...(ownerState.size === "lg" && {
                "--Button-minHeight": "3rem",
              }),
            },
            ownerState.variant === "solid" && {
              boxShadow: theme.vars.shadow.xs,
            },
          ],
        },
      },
      JoyRadio: {
        styleOverrides: {
          root: ({ theme, ownerState }) => ({
            gap: theme.spacing(1),
            ...(ownerState.size === "md" && {
              gap: theme.spacing(2),
            }),
            ...(ownerState.size === "lg" && {
              gap: theme.spacing(2),
            }),
          }),
        },
      },
      JoyIconButton: {
        styleOverrides: {
          root: ({ theme }) => ({
            borderRadius: theme.radius.xl,
          }),
        },
      },
      JoyMenuButton: {
        styleOverrides: {
          root: ({ theme }) => ({
            borderRadius: theme.radius.lg,
          }),
        },
      },
      JoyTable: {
        styleOverrides: {
          root: ({ theme }) => ({
            "tbody tr td:first-child": {
              borderTopLeftRadius: theme.radius.sm,
              borderBottomLeftRadius: theme.radius.sm,
            },
            "tbody tr td:last-child": {
              borderTopRightRadius: theme.radius.sm,
              borderBottomRightRadius: theme.radius.sm,
            },
            "--unstable_TableCell-height": "none",
          }),
        },
      },
      JoyInput: {
        styleOverrides: {
          root: ({ theme }) => ({
            "--Input-radius": theme.radius.xl,
          }),
        },
      },
      JoyModalClose: {
        styleOverrides: {
          root: ({ theme }) => ({
            borderRadius: theme.radius.xl,
          }),
        },
      },
      JoySlider: {
        styleOverrides: {
          valueLabel: {
            color: "var(--joy-palette-neutral-900)",
          },
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: highDPIScreen ? breakpoints.highDPI.sm : breakpoints.standardDPI.sm,
        md: highDPIScreen ? breakpoints.highDPI.md : breakpoints.standardDPI.md,
        lg: highDPIScreen ? breakpoints.highDPI.lg : breakpoints.standardDPI.lg,
        xl: highDPIScreen ? breakpoints.highDPI.xl : breakpoints.standardDPI.xl,
      },
    },
  };

  return extendTheme(deepmerge(defaultThemeOptions, override));
};

export default createTheme;
