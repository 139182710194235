type DefaultColorShades =
  | 50
  | 100
  | 200
  | 300
  | 400
  | 500
  | 600
  | 700
  | 800
  | 900;

type ColorShades = Record<DefaultColorShades, `#${string}`>;

const brandColors = {
  grey: {
    50: "#F4F6F7",
    100: "#F1F3F4",
    200: "#EBEEF2",
    300: "#D6DCE2",
    400: "#C6CED6",
    500: "#A8B4C0",
    600: "#6F7C89",
    700: "#3E4B59",
    800: "#293746",
    900: "#232F3D",
  },
  blue: {
    50: "#F7FAFE",
    100: "#DDEAFA",
    200: "#BCD5F4",
    300: "#9AC0EF",
    400: "#79ABE9",
    500: "#5796E4",
    600: "#4678B6",
    700: "#345A89",
    800: "#233C5B",
    900: "#051423",
  },
  yellow: {
    50: "#FEF4D6",
    100: "#FDE8AD",
    200: "#FBDD85",
    300: "#FAD66B",
    400: "#FAD15C",
    500: "#F9C633",
    600: "#F3B607",
    700: "#AD8205",
    800: "#644F14",
    900: "#1D1002",
  },
  green: {
    50: "#F6FEF6",
    100: "#CFF2E3",
    200: "#9FE5C7",
    300: "#6ED7AC",
    400: "#3ECA90",
    500: "#0EBD74",
    600: "#0B975D",
    700: "#087146",
    800: "#064C2E",
    900: "#021D02",
  },
  red: {
    50: "#FDEEF1",
    100: "#F9D9DF",
    200: "#F3B4BF",
    300: "#EC8EA0",
    400: "#E66980",
    500: "#E04360",
    600: "#B3364D",
    700: "#86283A",
    800: "#5A1B26",
    900: "#2D0D13",
  },
} as const;

const utilityColors = {
  lightGreen: {
    50: "#EBFAF3",
    100: "#CFF2E3",
    200: "#9FE5C7",
    300: "#6ED7AC",
    400: "#3ECA90",
    500: "#0EBD74",
    600: "#0B975D",
    700: "#087146",
    800: "#064C2E",
    900: "#042F1D",
  },
  lime: {
    50: "#F7FEE7",
    100: "#ECFCCB",
    200: "#D9F99D",
    300: "#BEF264",
    400: "#A3E635",
    500: "#84CC16",
    600: "#65A30D",
    700: "#4D7C0F",
    800: "#3F6212",
    900: "#1A2E05",
  },
  pink: {
    50: "#FEF6FB",
    100: "#FBE2F1",
    200: "#F6C5E3",
    300: "#F2A9D4",
    400: "#ED8CC6",
    500: "#E96FB8",
    600: "#BA5993",
    700: "#8C436E",
    800: "#5D2C4A",
    900: "#2F1625",
  },
  orange: {
    50: "#FFF7ED",
    100: "#FFEDD5",
    200: "#FED7AA",
    300: "#FDBA74",
    400: "#FB923C",
    500: "#F97316",
    600: "#EA580C",
    700: "#C2410C",
    800: "#9A3412",
    900: "#431407",
  },
  aqua: {
    50: "#ECFEFF",
    100: "#CFFAFE",
    200: "#A5F3FC",
    300: "#67E8F9",
    400: "#22D3EE",
    500: "#06B6D4",
    600: "#0891B2",
    700: "#0E7490",
    800: "#155E75",
    900: "#083344",
  },
  aubergine: {
    50: "#F3ECF4",
    100: "#EADDEB",
    200: "#D5BBD7",
    300: "#C098C3",
    400: "#AB76AF",
    500: "#96549B",
    600: "#78437C",
    700: "#5A325D",
    800: "#3C223E",
    900: "#1E111F",
  },
  sand: {
    50: "#FAF6EF",
    100: "#F7EFE3",
    200: "#EFDFC7",
    300: "#E6CFAC",
    400: "#DEBF90",
    500: "#D6AF74",
    600: "#AB8C5D",
    700: "#806946",
    800: "#56462E",
    900: "#3B2A12",
  },
  moss: {
    50: "#F9FBFB",
    100: "#EFF5F4",
    200: "#DFEBEA",
    300: "#CEE0DF",
    400: "#BED6D5",
    500: "#AECCCA",
    600: "#8BA3A2",
    700: "#687A79",
    800: "#465251",
    900: "#2F3736",
  },
  purple: {
    50: "#FAF5FF",
    100: "#EDE9FE",
    200: "#DDD6FE",
    300: "#C4B5FD",
    400: "#A78BFA",
    500: "#8B5CF6",
    600: "#7C3AED",
    700: "#6D28D9",
    800: "#5B21B6",
    900: "#2E1065",
  },
} as const;

const alphaColors = {
  alphaBlack: {
    50: "#0000000D",
    100: "#0000001A",
    200: "#00000033",
    300: "#0000004D",
    400: "#00000066",
    500: "#00000080",
    600: "#00000099",
    700: "#000000B2",
    800: "#000000CC",
    900: "#000000E5",
  },
  alphaWhite: {
    50: "#FFFFFF0D",
    100: "#FFFFFF1A",
    200: "#FFFFFF33",
    300: "#FFFFFF4D",
    400: "#FFFFFF66",
    500: "#FFFFFF80",
    600: "#FFFFFF99",
    700: "#FFFFFFB2",
    800: "#FFFFFFCC",
    900: "#FFFFFFE5",
  },
  alphaBlue: {
    50: "#5796E40D",
    100: "#5796E41A",
    200: "#5796E433",
    300: "#5796E44D",
    400: "#5796E466",
    500: "#5796E480",
    600: "#5796E499",
    700: "#5796E4B2",
    800: "#5796E4CC",
    900: "#5796E4E5",
  },
  alphaYellow: {
    50: "#F9C6330D",
    100: "#F9C6331A",
    200: "#F9C63333",
    300: "#F9C6334D",
    400: "#F9C63366",
    500: "#F9C63380",
    600: "#F9C63399",
    700: "#F9C633B2",
    800: "#F9C633CC",
    900: "#F9C633E5",
  },
  alphaRed: {
    50: "#E043600D",
    100: "#E043601A",
    200: "#E0436033",
    300: "#E043604D",
    400: "#E0436066",
    500: "#E0436080",
    600: "#E0436099",
    700: "#E04360B2",
    800: "#E04360CC",
    900: "#E04360E5",
  },
  alphaLightGreen: {
    50: "#0EBD740D",
    100: "#0EBD741A",
    200: "#0EBD7433",
    300: "#0EBD744D",
    400: "#0EBD7466",
    500: "#0EBD7480",
    600: "#0EBD7499",
    700: "#0EBD74B2",
    800: "#0EBD74CC",
    900: "#0EBD74E5",
  },
  alphaDarkGreen: {
    50: "#0498590D",
    100: "#0498591A",
    200: "#04985933",
    300: "#0498594D",
    400: "#04985966",
    500: "#04985980",
    600: "#04985999",
    700: "#049859B2",
    800: "#049859CC",
    900: "#049859E5",
  },
  alphaLime: {
    50: "#84CC160D",
    100: "#84CC161A",
    200: "#84CC1633",
    300: "#84CC164D",
    400: "#84CC1666",
    500: "#84CC1680",
    600: "#84CC1699",
    700: "#84CC16B2",
    800: "#84CC16CC",
    900: "#84CC16E5",
  },
  alphaPink: {
    50: "#E96FB80D",
    100: "#E96FB81A",
    200: "#E96FB833",
    300: "#E96FB84D",
    400: "#E96FB866",
    500: "#E96FB880",
    600: "#E96FB899",
    700: "#E96FB8B2",
    800: "#E96FB8CC",
    900: "#E96FB8E5",
  },
  alphaOrange: {
    50: "#F973160D",
    100: "#F973161A",
    200: "#F9731633",
    300: "#F973164D",
    400: "#F9731666",
    500: "#F9731680",
    600: "#F9731699",
    700: "#F97316B2",
    800: "#F97316CC",
    900: "#F97316E5",
  },
  alphaAqua: {
    50: "#06B6D40D",
    100: "#06B6D41A",
    200: "#06B6D433",
    300: "#06B6D44D",
    400: "#06B6D466",
    500: "#06B6D480",
    600: "#06B6D499",
    700: "#06B6D4B2",
    800: "#06B6D4CC",
    900: "#06B6D4E5",
  },
  alphaAubergine: {
    50: "#96549B0D",
    100: "#96549B1A",
    200: "#96549B33",
    300: "#96549B4D",
    400: "#96549B66",
    500: "#96549B80",
    600: "#96549B99",
    700: "#96549BB2",
    800: "#96549BCC",
    900: "#96549BE5",
  },
  alphaSand: {
    50: "#D6AF740D",
    100: "#D6AF741A",
    200: "#D6AF7433",
    300: "#D6AF744D",
    400: "#D6AF7466",
    500: "#D6AF7480",
    600: "#D6AF7499",
    700: "#D6AF74B2",
    800: "#D6AF74CC",
    900: "#D6AF74E5",
  },
  alphaMoss: {
    50: "#AECCCA0D",
    100: "#AECCCA1A",
    200: "#AECCCA33",
    300: "#AECCCA4D",
    400: "#AECCCA66",
    500: "#AECCCA80",
    600: "#AECCCA99",
    700: "#AECCCAB2",
    800: "#AECCCACC",
    900: "#AECCCAE5",
  },
  alphaPurple: {
    50: "#8B5CF60D",
    100: "#8B5CF61A",
    200: "#8B5CF633",
    300: "#8B5CF64D",
    400: "#8B5CF666",
    500: "#8B5CF680",
    600: "#8B5CF699",
    700: "#8B5CF6B2",
    800: "#8B5CF6CC",
    900: "#8B5CF6E5",
  },
} as const;

const colors: Record<string, ColorShades> = {
  ...brandColors,
  ...utilityColors,
  ...alphaColors,
} as const;

export default colors;
