import { ThemeWrapper } from "library-ui-components";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes/router";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { LoadingPage } from "library-ui-components/pages";
import { BrowserAuthWrapper } from "library-frontend-utils/auth";
import config from "../deploy";

const queryClient = new QueryClient();

function App() {
  return (
    <BrowserAuthWrapper config={config}>
      <QueryClientProvider client={queryClient}>
        <ThemeWrapper>
          <RouterProvider router={router} fallbackElement={<LoadingPage />} />
          <ReactQueryDevtools initialIsOpen={false} />
        </ThemeWrapper>
      </QueryClientProvider>
    </BrowserAuthWrapper>
  );
}

export default App;
